<template>
  <app-form-view
    app="audit"
    model="audittemplateheader"
    api-url="audit/audit-program-template-header/"
    :title="$t('menu.auditProgramTemplate')"
    :state="formData.state"
    :stateItems="stateItems"
    :prepare-data="prepareData"
    :approveItems="approveItems"
    v-model="formData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="12" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.auditProgramName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="12" class="app-input--approve-wrapper">
          <app-input
            name="master_audit_program_template"
            type="select-server"
            :label="$t('fields.masterAuditProgramTemplate')"
            :view="view"
            :binds="{
              apiUrl: 'audit/master-audit-program-template-header/?active=true'
            }"
            v-model="formData.master_audit_template_header_id"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveTemplateSubCategory"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_master_audit_template_header_id"
          />
        </v-col>
        <v-col cols="12" lg="12">
          <app-input
            name="last_modify_date"
            :view="view"
            :disabled="true"
            v-model="formData.last_modify_date"
            :label="$t('fields.lastModifyDate')"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" class="app-input--approve-wrapper">
          <app-input
            name="isPreAudit"
            type="switch"
            :label="$t('fields.preAudit')"
            :view="view"
            v-model="formData.is_pre_audit"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveisPreAudit"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_is_pre_audit"
          />
        </v-col>
        <v-col cols="12" lg="12" class="app-input--approve-wrapper">
          <app-input
            name="isAudit"
            type="switch"
            :label="$t('fields.audit')"
            :view="view"
            v-model="formData.is_audit"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveisAudit"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_is_audit"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" class="app-input--approve-wrapper">
          <app-input
            name="isPostAudit"
            type="switch"
            :label="$t('fields.postAudit')"
            :view="view"
            v-model="formData.is_post_audit"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveisPostAudit"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_is_post_audit"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" class="app-input--approve-wrapper">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
      <v-card v-if="mode !== 'create'">
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>Description</v-tab>
          <v-tab>Objective</v-tab>
          <v-tab>Risk Profile</v-tab>
          <v-tab>Activity Profile</v-tab>
          <v-tab>Task</v-tab>
          <v-tab>Business</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <!-- Description Tab -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-input
                  name="Description"
                  type="html-editor"
                  :label="$t('fields.description')"
                  :view="view"
                  v-model="formData.description"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Objective -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  app="audit"
                  model="audittemplateobjective"
                  :headers="objectiveHeader"
                  :serverItems="objectiveItems"
                  :clientItems="objectiveItems"
                  :loading="loading"
                  :server-items-length="objectiveServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getObjectiveItems"
                  @delete="onDeleteObjectiveItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Objective</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog v-model="dialogObjective" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteObjectiveItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedObjectiveItem.sequence"
                                        name="Sequence"
                                        rules="required|min_value:1"
                                        :label="$t('fields.sequence')"
                                        type="number"
                                        :view="view"
                                      ></app-input>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="
                                          editedObjectiveItem.objective_id
                                        "
                                        name="document-template"
                                        type="select-server"
                                        :label="$t('fields.objectiveName')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'objective/objective/?active=true'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeObjective"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveObjective"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Risk Profile -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  :isSelecteable="false"
                  app="audit"
                  model="audittemplateriskprofile"
                  :headers="riskProfileHeader"
                  :serverItems="riskProfileItems"
                  :clientItems="riskProfileItems"
                  :loading="loading"
                  :server-items-length="riskProfileServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getRiskProfileItems"
                  @delete="onDeleteRiskProfileItem"
                  subItemKey="risk_profile_id"
                  item-key="id"
                  show-expand
                  :isDescription="true"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>RiskProfile</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRiskProfile"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteRiskProfileItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="
                                          editedRiskProfileItem.risk_profile_id
                                        "
                                        name="audit-program"
                                        type="select-server"
                                        :label="$t('fields.riskProfile')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'risk/risk-profile-template-header/?active=true'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeRiskProfile"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveRiskProfile"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Activity Profile -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  :isSelecteable="false"
                  app="audit"
                  model="audittemplateactivityprofile"
                  :headers="activityProfileHeader"
                  :serverItems="activityProfileItems"
                  :clientItems="activityProfileItems"
                  :loading="loading"
                  :server-items-length="activityProfileServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getActivityProfileItems"
                  @delete="onDeleteActivityProfileItem"
                  subItemKey="activity_profile_id"
                  item-key="id"
                  show-expand
                  :isDescription="true"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Activity Profile</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogActivityProfile"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteActivityProfileItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="
                                          editedActivityProfileItem.activity_profile_id
                                        "
                                        name="audit-program"
                                        type="select-server"
                                        :label="$t('fields.activityProfile')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'activity/activity-profile-header/?active=true'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeActivityProfile"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveActivityProfile"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Task -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  hide-edit
                  :isSelecteable="false"
                  app="audit"
                  model="audittemplatetaskitem"
                  :headers="taskItemHeader"
                  :serverItems="taskItemItems"
                  :clientItems="taskItemItems"
                  :loading="loading"
                  :server-items-length="taskItemServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  v-model="selected"
                  @server="getTaskItemItems"
                  @delete="onDeleteTaskItemItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Task</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog v-model="dialogTaskItem" max-width="500px">
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteTaskItemItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedTaskItemItem.sequence"
                                        name="sequence"
                                        :label="$t('fields.sequence')"
                                        type="number"
                                        :view="view"
                                      ></app-input>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="
                                          editedTaskItemItem.parent_audit_template_header_id
                                        "
                                        name="audit-program"
                                        type="select-server"
                                        :label="$t('fields.auditProgram')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'audit/audit-program-template-header/?active=true'
                                        }"
                                        :disabled="
                                          Boolean(
                                            editedTaskItemItem.task_template_header_id
                                          )
                                        "
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="
                                          editedTaskItemItem.task_template_header_id
                                        "
                                        name="task"
                                        type="select-server"
                                        :label="$t('fields.task')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'task/task-template-header/?active=true'
                                        }"
                                        :disabled="
                                          Boolean(
                                            editedTaskItemItem.parent_audit_template_header_id
                                          )
                                        "
                                      >
                                      </app-input>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                      <app-input
                                        v-model="editedTaskItemItem.note"
                                        name="note"
                                        :label="$t('fields.note')"
                                        :view="view"
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeTaskItem"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveTaskItem"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Business -->
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  ref="table"
                  app="audit"
                  model="audittemplatebusiness"
                  :headers="businessItemHeader"
                  :serverItems="businessItemItems"
                  :clientItems="businessItemItems"
                  :loading="loading"
                  :server-items-length="businessItemServerItemsLength"
                  :hide-delete="mode !== 'edit'"
                  :hide-edit="mode !== 'edit'"
                  v-model="selected"
                  @server="getBusinessItemItems"
                  @delete="onDeleteBusinessItemItem"
                  @edit="onEditBusinessItem"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Business</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogBusinessItem"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>
                                <v-btn
                                  v-show="selected.length && mode === 'edit'"
                                  color="error"
                                  @click="onDeleteBusinessItemItem(selected)"
                                >
                                  {{ $t('btn.delete') }}
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        v-model="
                                          editedBusinessItemItem.industry_id
                                        "
                                        name="industry"
                                        type="select-server"
                                        :label="$t('fields.industry')"
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            'doc-template/industry/?active=true'
                                        }"
                                        @input="getBusinessCateItem"
                                      ></app-input>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        v-model="
                                          editedBusinessItemItem.business_cate_id
                                        "
                                        name="category"
                                        type="select-server"
                                        :label="$t('fields.category')"
                                        :view="view"
                                        :binds="{
                                          apiUrl: businessCateUrl
                                        }"
                                        :disabled="
                                          !Boolean(
                                            editedBusinessItemItem.industry_id
                                          )
                                        "
                                        @input="getBusinessTypeItem"
                                      ></app-input>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <app-input
                                        v-model="
                                          editedBusinessItemItem.business_type_id
                                        "
                                        name="buztype"
                                        type="select-server"
                                        :label="$t('fields.businessType')"
                                        :view="view"
                                        :binds="{
                                          apiUrl: businessTypeUrl
                                        }"
                                        :disabled="
                                          !Boolean(
                                            editedBusinessItemItem.business_cate_id
                                          )
                                        "
                                      ></app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="closeBusinessItem"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="saveBusinessItem"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput'
import AppTable from '@components/AppTable'

export default {
  name: 'audit-profile-form',
  components: {
    AppInput,
    AppFormView,
    AppTable
  },
  data() {
    return {
      tab: null,
      loading: false,
      formData: {
        state: 'draft'
      },
      approveItems: [
        'approve_name',
        'approve_master_audit_template_header_id',
        'approve_is_pre_audit',
        'approve_is_audit',
        'approve_is_post_audit'
      ],
      auditTemplateDetailItems: [],
      auditTemplateDetailSelected: [],
      auditTemplateClientItems: [],
      auditTemplateDetailItemsLength: 0,
      editId: null,
      editedItem: {
        sequence: null,
        auditing_procedure: null
      },
      objectiveItems: [],
      objectiveServerItemsLength: 0,
      dialogObjective: false,
      editedObjectiveItem: {},
      riskProfileItems: [],
      riskProfileServerItemsLength: 0,
      dialogRiskProfile: false,
      editedRiskProfileItem: [],
      activityProfileItems: [],
      activityProfileServerItemsLength: 0,
      dialogActivityProfile: false,
      editedActivityProfileItem: [],
      taskItemItems: [],
      taskItemServerItemsLength: 0,
      dialogTaskItem: false,
      editedTaskItemItem: [],
      businessItemItems: [],
      businessItemServerItemsLength: 0,
      dialogBusinessItem: false,
      editedBusinessItemItem: [],
      apiMethod: 'post',
      selected: [],
      businessCateUrl: null,
      businessTypeUrl: null
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    objectiveHeader() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.name'),
          value: 'objective_id.name',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    riskProfileHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'risk_profile_id.name',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    activityProfileHeader() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'activity_profile_id.name',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    taskItemHeader() {
      return [
        {
          text: this.$t('fields.sequence'),
          value: 'sequence',
          hideFilter: true
        },
        {
          text: this.$t('fields.auditProgram'),
          value: 'parent_audit_template_header_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.task'),
          value: 'task_template_header_id.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    businessItemHeader() {
      return [
        {
          text: this.$t('fields.businessIndustry'),
          value: 'industry_id.name'
        },
        {
          text: this.$t('fields.businessCategory'),
          value: 'business_cate_id.name'
        },
        {
          text: this.$t('fields.businessType'),
          value: 'business_type_id.name'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  created() {
    this.$store.dispatch('addBreadcrumb', {
      name: 'auditProgramTemplateCreate'
    })
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'auditProgramTemplateEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'auditProgramTemplateEdit',
          text: value ?? 'N/A',
          to: {
            name: 'auditProgramTemplateEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    prepareData(data) {
      if (
        data.master_audit_template_header_id !== null &&
        data.master_audit_template_header_id !== undefined
      ) {
        data.master_audit_template_header_id =
          data.master_audit_template_header_id.id
      }
      return data
    },
    getObjectiveItems() {
      this.loading = true
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-program-template-objective?audit_template_header_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.objectiveServerItemsLength = data.count
          this.objectiveItems = data.results
          this.loading = false
        })
      }
      this.loading = false
    },
    onDeleteObjectiveItem(item) {
      this.onDeleteObjective(item)
    },
    onDeleteObjective(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-program-template-objective/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getObjectiveItems()
        })
      })
      this.loading = false
    },
    closeObjective() {
      this.dialogObjective = false
      this.$nextTick(() => {
        this.editedObjectiveItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async saveObjective() {
      if (
        this.editedObjectiveItem.objective_id !== null &&
        this.editedObjectiveItem.objective_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.audit_template_header_id = this.$route.params.id
        data.objective_id = this.editedObjectiveItem.objective_id.id
        data.sequence = this.editedObjectiveItem.sequence

        await this.$api({
          method: 'post',
          url: `audit/audit-program-template-objective/`,
          data
        })
        this.editedObjectiveItem = {}
        this.closeObjective()
        this.getObjectiveItems()
      }
    },
    getRiskProfileItems() {
      this.loading = true

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-program-template-risk-profile?audit_template_header_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.riskProfileServerItemsLength = data.count
          this.riskProfileItems = data.results
        })
      }
      this.loading = false
    },
    onDeleteRiskProfileItem(item) {
      this.onDeleteRiskProfile(item)
    },
    onDeleteRiskProfile(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-program-template-risk-profile/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRiskProfileItems()
        })
      })
      this.loading = false
    },
    closeRiskProfile() {
      this.dialogRiskProfile = false
      this.$nextTick(() => {
        this.editedRiskProfileItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async saveRiskProfile() {
      if (
        this.editedRiskProfileItem.risk_profile_id !== null &&
        this.editedRiskProfileItem.risk_profile_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.audit_template_header_id = this.$route.params.id
        data.risk_profile_id = this.editedRiskProfileItem.risk_profile_id.id

        await this.$api({
          method: 'post',
          url: `audit/audit-program-template-risk-profile/`,
          data
        })
        this.editedRiskProfileItem = {}
        this.closeRiskProfile()
        this.getRiskProfileItems()
      }
    },
    getActivityProfileItems() {
      this.loading = true

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-program-template-activity-profile?audit_template_header_id_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.activityProfileServerItemsLength = data.count
          this.activityProfileItems = data.results
        })
      }
      this.loading = false
    },
    onDeleteActivityProfileItem(item) {
      this.onDeleteActivityProfile(item)
    },
    onDeleteActivityProfile(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-program-template-activity-profile/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getActivityProfileItems()
        })
      })
      this.loading = false
    },
    closeActivityProfile() {
      this.dialogActivityProfile = false
      this.$nextTick(() => {
        this.editedActivityProfileItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async saveActivityProfile() {
      if (
        this.editedActivityProfileItem.activity_profile_id !== null &&
        this.editedActivityProfileItem.activity_profile_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.audit_template_header_id = this.$route.params.id
        data.activity_profile_id =
          this.editedActivityProfileItem.activity_profile_id.id

        await this.$api({
          method: 'post',
          url: `audit/audit-program-template-activity-profile/`,
          data
        })
        this.editedActivityProfileItem = {}
        this.closeActivityProfile()
        this.getActivityProfileItems()
      }
    },
    getTaskItemItems() {
      this.loading = true

      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-program-template-task-item?audit_template_task_id__audit_template_header_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.taskItemServerItemsLength = data.count
          this.taskItemItems = data.results
        })
      }
      this.loading = false
    },
    onDeleteTaskItemItem(item) {
      this.onDeleteTaskItem(item)
    },
    onDeleteTaskItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-program-template-task/`,
          data: {
            pks: items.map((value) => value.audit_template_task_id.id)
          }
        }).then(() => {
          this.selected = []
          this.getTaskItemItems()
        })
      })
      this.loading = false
    },
    closeTaskItem() {
      this.dialogTaskItem = false
      this.$nextTick(() => {
        this.editedTaskItemItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async saveTaskItem() {
      if (
        (this.editedTaskItemItem.task_template_header_id !== null &&
          this.editedTaskItemItem.task_template_header_id !== undefined) ||
        (this.editedTaskItemItem.parent_audit_template_header_id !== null &&
          this.editedTaskItemItem.parent_audit_template_header_id !== undefined)
      ) {
        this.loading = true
        const data = {}
        data.audit_template_header_id = this.$route.params.id
        // data.master_activity_profile_id =
        //   this.editedActivityProfileItem.master_activity_profile_id.id

        const response1 = await this.$api({
          method: 'post',
          url: `audit/audit-program-template-task/`,
          data,
          hideSuccessAlert: true
        })

        data.audit_template_task_id = response1.data.detail.id
        data.sequence = this.editedTaskItemItem.sequence
        if (
          this.editedTaskItemItem.parent_audit_template_header_id !== null &&
          this.editedTaskItemItem.parent_audit_template_header_id !== undefined
        ) {
          data.parent_audit_template_header_id =
            this.editedTaskItemItem.parent_audit_template_header_id.id
        }
        if (
          this.editedTaskItemItem.task_template_header_id !== null &&
          this.editedTaskItemItem.task_template_header_id !== undefined
        ) {
          data.task_template_header_id =
            this.editedTaskItemItem.task_template_header_id.id
        }
        data.note = this.editedTaskItemItem.note

        await this.$api({
          method: 'post',
          url: `audit/audit-program-template-task-item/`,
          data
        })

        this.editedTaskItemItem = {}
        this.closeTaskItem()
        this.getTaskItemItems()
      }
    },
    getBusinessItemItems() {
      if (
        this.$route.params.id !== null &&
        this.$route.params.id !== undefined
      ) {
        this.$api({
          method: 'get',
          url: `audit/audit-program-template-business/?audit_template_header_id=${this.$route.params.id}`,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.businessItemServerItemsLength = data.count
          this.businessItemItems = data.results
        })
      }
      this.loading = false
    },
    getBusinessCateItem() {
      var url = ''
      if (
        this.editedBusinessItemItem.industry_id !== null &&
        this.editedBusinessItemItem.industry_id !== undefined
      ) {
        url =
          'doc-template/category/?active=true&industry_id=' +
          this.editedBusinessItemItem.industry_id.id
      } else {
        url = 'doc-template/category/?active=true'
      }
      this.businessCateUrl = url
    },
    getBusinessTypeItem() {
      var url = ''
      if (
        this.editedBusinessItemItem.business_type_id !== null &&
        this.editedBusinessItemItem.business_type_id !== undefined
      ) {
        url =
          'doc-template/buztype/?active=true&business_cate_id=' +
          this.editedBusinessItemItem.business_type_id.id
      } else {
        url = 'doc-template/buztype/?active=true'
      }
      this.businessTypeUrl = url
    },
    onDeleteBusinessItemItem(item) {
      this.onDeleteBusinessItem(item)
    },
    onDeleteBusinessItem(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `audit/audit-program-template-business/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getBusinessItemItems()
        })
      })
      this.loading = false
    },
    closeBusinessItem() {
      this.dialogBusinessItem = false
      this.$nextTick(() => {
        this.editedBusinessItemItem = Object.assign({}, this.defaultItem)
        this.editedItemIndex = -1
      })
      this.loading = false
    },
    async saveBusinessItem() {
      if (
        this.editedBusinessItemItem.industry_id !== null &&
        this.editedBusinessItemItem.industry_id !== undefined
      ) {
        this.loading = true
        const data = {}
        data.audit_template_header_id = this.$route.params.id
        data.industry_id = this.editedBusinessItemItem.industry_id.id
        if (
          this.editedBusinessItemItem.business_cate_id !== null &&
          this.editedBusinessItemItem.business_cate_id !== undefined
        ) {
          data.business_cate_id =
            this.editedBusinessItemItem.business_cate_id.id
        }
        if (
          this.editedBusinessItemItem.business_type_id !== null &&
          this.editedBusinessItemItem.business_type_id !== undefined
        ) {
          data.business_type_id =
            this.editedBusinessItemItem.business_type_id.id
        }
        await this.$api({
          method: this.apiMethod,
          url: `audit/audit-program-template-business/${
            this.editId ? this.editId + '/' : ''
          }`,
          data
        })
        this.loading = false
        this.editedBusinessItemItem = {}
        this.closeBusinessItem()
        this.getBusinessItemItems()
      }
    },
    onEditBusinessItem(item) {
      this.editedBusinessItemItem = Object.assign({}, item)
      this.apiMethod = 'put'
      this.editId = item.id
      this.dialogBusinessItem = true
    }
  }
}
</script>
